import React from "react";

export const Process = () => {
  return (

    <>
      <div id="proces" className="w-100">
        <img src="/img/work-flow-image.svg" style={{ width: "100%" }} alt="" />
      </div>

      <div id="about" className="work-flow">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-6">
              {" "}
              <img src="/img/post-requirement.svg" className="img-responsive mx-auto" alt="" />{" "}
            </div>
            <div className="col-xs-12 col-md-6">
              <div className="about-text">
                <h2>Your one-stop dock for posting requirements and finding the best offers.</h2>
                <ul>
                  <li>Trust & Transparency: Highlight secure communication and transparent bidding processes to build trust.</li>
                  <li>Seamless Experience: Ensure a user-friendly interface for both buyers and sellers to simplify their tasks.</li>
                  <li>Verified Users: Implement a verification system for buyers and sellers to ensure authenticity.</li>
                  <li>Secure Contact Sharing: Emphasize that buyer contact details are shared only after bid finalization. </li>
                  <li>Smart Notifications: Offer real-time updates for both buyers and sellers about bids and requirements.</li>
                  <li>Rating System: Allow users to rate each other post-transaction to maintain quality and reliability.</li>
                  <li>Privacy Protection: Stress that sensitive information remains secure and shared responsibly. </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>

  );
};
