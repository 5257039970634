import React from "react";

export const Video = () => {
  return (
    <section>
      <div className="container video-sec">
        <div className="text-center mb-4">
          <h2>Simple and secure platform for requirement-based connections</h2>
        </div>
        <div className="container mx-auto">
          <iframe
            style={{ width: "100%", height: "60%", borderRadius: "10px" }}
            src="https://www.youtube.com/embed/1_NuEkMyP3I?si=EMKn7rVqku2D9_M5&autoplay=1"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </section>
  );
};
